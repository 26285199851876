.header-container {
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding-left: 20px;
    padding-right: 20px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.header-logo {
    height: 100px;
    width:auto
}

.header-icons {
    height: 40px;
    width: auto;
}

.header-item-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.header-item-text {
    color: #3c3c3c;
    font-weight: bold;
    text-decoration: none;
}